import BasePlugin from '../BasePlugin'

export default class CheckEDMSStatus extends BasePlugin {
  async execute () {
    this.context.$http({
      method: 'post',
      url: `${this.context.$config.api}/registryservice/plugins/execute/CheckEDMSStatusCommand`,
    }).then((response) => {
    })
  }
}
